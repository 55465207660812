<template>
  <!-- 公告列表 -->
  <div>
    <div class="notice-cont">
      <div class="notice-list-cont">
        <div class="notice-list-head f-c">
          <div class="f-c" style="margin-left: 55px;">
            <img class="head-img" src="../../assets/img/news.png" alt="">
            <span class="head-title">新闻</span>
          </div>
          <div class="notice-head-line"></div>
        </div>
        <div class="notice-lists">
          <div class="lists-cont">
            <div class="cont-left">
              <img src="" alt="">
            </div>
            <div class="cont-right">
              <div class="right-title">【重要信息】关于XXXXX</div>
              <div class="right-cont">官方公告</div>
              <div class="right-times">2024年03月14日 12:00:00</div>
            </div>
          </div>
          <div class="lists-cont">
            <div class="cont-left">
              <img src="" alt="">
            </div>
            <div class="cont-right">
              <div class="right-title">【重要信息】关于XXXXX</div>
              <div class="right-cont">官方公告</div>
              <div class="right-times">2024年03月14日 12:00:00</div>
            </div>
          </div>
          <div class="lists-cont">
            <div class="cont-left">
              <img src="" alt="">
            </div>
            <div class="cont-right">
              <div class="right-title">【重要信息】关于XXXXX</div>
              <div class="right-cont">官方公告</div>
              <div class="right-times">2024年03月14日 12:00:00</div>
            </div>
          </div>
          <div class="lists-cont">
            <div class="cont-left">
              <img src="" alt="">
            </div>
            <div class="cont-right">
              <div class="right-title">【重要信息】关于XXXXX</div>
              <div class="right-cont">官方公告</div>
              <div class="right-times">2024年03月14日 12:00:00</div>
            </div>
          </div>
        </div>
        <div class="notice-foot">
          <el-pagination
            layout="prev, pager, next"
            :total="50">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { announcements, } from './index';

export default {
  name: 'noticeList',
  data(){
    return{

    }
  },
  mounted(){
    this.getList();
  },
  methods:{
    getList(){
      announcements().then(res => {
        console.log(res);
      }).catch(error => {
        if(error) this.$message.error(error.msg);
      });
    },
  }
}
</script>
<style scoped lang="less">
.line{
    width: 2px;
    height: 20px;
    background: #E9B96B;
    margin-left: 20px;
}
.head{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #24242D;
}
.head-img{
    width: 40px;
    height: 40px;
    margin-right: 35px;
}
.head-cont{
    display: flex;
    align-items: center;
}
.head-cont span{
    font-size: 20px;
    color: #636378;
    margin-left: 20px;
    cursor: pointer;
}
.head-cont span:hover{
    color: #B7B7DE;
}
.notice-cont{
  /* display: flex; */
  position: fixed;
  background: url(../../assets/img/bg.jpg)center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.notice-list-cont{
  width: 1430px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 80px;
}
.notice-list-head{
  position: relative;
  height: 150px;
  border-radius: 25px 25px 0px 0px;
  background: linear-gradient(180deg, rgba(233, 185, 107, 0.1) 0%, rgba(233, 185, 107, 0) 99%), #24242D;
  .head-img{
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }
  .head-title{
    font-size: 30px;
    color: #E9B96B;
  }
}
.notice-head-line{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(270deg, rgba(233, 185, 107, 0) 0%, #E9B96B 51%, rgba(233, 185, 107, 0) 100%);
}
.notice-lists{
  background: #24242D;
  padding: 15px 20px 0 20px;
}
.lists-cont{
  display: flex;
  margin-bottom: 15px;
  padding: 30px 30px 30px 40px;
  border-radius: 15px;
  background: #2E2E38;
}
.notice-lists .lists-cont:last-child{
  margin-bottom: 0;
}
.notice-foot{
  height: 75px;
  border-radius: 0px 0px 25px 25px;
  background: #24242D;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-left{
  width: 265px;
  height: 180px;
  border-radius: 10px;
  margin-right: 20px;
}
.cont-left img{
  width: 100%;
  height: 100%;
}
.cont-right{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.right-title{
  font-size: 30px;
  color: #E9B96B;
  margin-top: 35px;
  margin-bottom: 20px;
}
.right-cont{
  font-size: 18px;
  color: #636378;
  flex: 1;
}
.right-times{
  text-align: right;
  font-size: 18px;
  color: #636378;
}
/deep/ .el-pagination,
/deep/ .el-pagination button:disabled,
/deep/ .el-pager li,
/deep/ .btn-next,
/deep/ .btn-prev{
  background: #24242D;
  color: #636378;
}
/deep/ .el-pager li.active{
  color: #E9B96B;
}
/deep/ .el-pagination button:hover{
  color: #E9B96B;
}
.f-c{
  display: flex;
  align-items: center;
}
</style>